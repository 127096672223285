import React from "react";

import { services } from "../data";

const Services = () => {
  return (
    <section id="services" className="section bg-tertiary">
      <div className="container mx-auto">
        <div className="flex flex-col items-center text-center">
          <h2 className="section-title before:content-services relative before:absolute before:opacity-40 before:-top-[2rem] before:-left-28 before:hidden before:lg:block">
            What I Do For Clients
          </h2>
          <p className="subtitle">
            I offer comprehensive web design services, including custom design,
            responsive development, UX design, front-end development, CMS
            integration, and ongoing maintenance. From captivating designs to
            seamless user experiences, I create websites that establish a strong
            online presence for businesses.
          </p>
        </div>
        <div className="grid lg:grid-cols-4 gap-8">
          {services.map((service, index) => {
            const { icon, name, description } = service;
            return (
              <div className="bg-secondary p-6 rounded-2xl" key={index}>
                <div className="text-accent rounded-sm w-12 h-12 flex justify-center items-center mb-4 text-2xl">
                  {icon}
                </div>
                <h4
                  className="text-xl font-medium
                mb-2"
                >
                  {name}
                </h4>
                <p>{description}</p>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Services;
