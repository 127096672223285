import React from "react";
import { testimonials } from "../data";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { Autoplay, Pagination } from "swiper";

const TestimonialSlider = () => {
  return (
    <Swiper
      modules={[Autoplay, Pagination]}
      spaceBetween={50}
      slidesPerView={1}
      autoplay={{ delay: 2500, disableOnInteraction: false }}
      pagination={{ clickable: true }}
      className="mySwiper"
    >
      {testimonials.map((item, index) => {
        const { authorImg, authorText, authorName, authorPosition, website } =
          item;
        return (
          <SwiperSlide key={index}>
            <div className="flex flex-col lg:flex-row gap-12 lg:gap-32">
              <div className="w-48 h-48 lg:w-[328px] lg:h-[328px]">
                <img className="rounded-2xl" src={authorImg} alt="" />
              </div>
              <div className="flex flex-col max-w-3xl">
                <h5 className="font-body text-2xl mb-8 italic font-normal">
                  {authorText}
                </h5>
                <p className="text-lg text-accent">{authorName}</p>
                <p>{authorPosition}</p>
                <a href={website} target="_blank" rel="noopener noreferrer">
                  Visit Website
                </a>
              </div>
            </div>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default TestimonialSlider;
