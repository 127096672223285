import React from "react";
import Image from "../assets/img/about.jpg";
import { Link } from "react-scroll";

const About = () => {
  return (
    <section id="about" className="section bg-secondary">
      <div className="container mx-auto">
        <div className="flex flex-col xl:flex-row gap-24">
          <img
            className="object-cover h-full w-[566px] mx-auto lg:mx-0 rounded-2xl"
            src={Image}
            alt=""
          />
          <div className="flex flex-col items-center text-center lg:items-start lg:text-left">
            <div className="flex flex-col">
              <h2 className="text-3xl lg:text-4xl font-medium lg:font-extrabold mb-3 before:content-about relative before:absolute before:opacity-40 before:-top-[2rem] before:hidden before:lg:block">
                Euan Munro
              </h2>
              <p className="mb-4 text-accent">Web Developer</p>
              <hr className="mb-8 opacity-5" />
              <p className="mb-8">
                Welcome to my web developer portfolio! I am passionate about
                crafting immersive and impactful digital experiences that push
                the boundaries of creativity and functionality. With a
                deep-rooted love for design and a strong technical acumen, I
                strive to deliver innovative solutions that leave a lasting
                impression on users.
              </p>
            </div>
            <Link
              to="contact"
              spy={true}
              smooth={true}
              duration={500}
              offset={-70}
            >
              <button className="btn btn-md bg-accent hover:bg-accent-hover transition-all">
                Contact Me
              </button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
