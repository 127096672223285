//  icons
import {
  FiTwitter,
  FiInstagram,
  FiGithub,
  FiLinkedin,
  FiLayout,
  FiSettings,
  FiPenTool,
  FiTag,
  FiMail,
  FiMapPin,
} from "react-icons/fi";

// companies icons
import FreelancerBrandIcon from "./assets/img/brands/freelancer.png";
import UpworkBrandIcon from "./assets/img/brands/upwork.png";
import FiverBrandIcon from "./assets/img/brands/fiverr.png";
import BehanceBrandIcon from "./assets/img/brands/behance.png";
import DribbbleBrandIcon from "./assets/img/brands/dribbble.png";

// projects images
import Project1 from "./assets/img/projects/p1.webp";
import Project2 from "./assets/img/projects/p2.webp";
import Project3 from "./assets/img/projects/p3.webp";
import Project4 from "./assets/img/projects/p4.webp";
import Project5 from "./assets/img/projects/p5.webp";
import Project6 from "./assets/img/projects/p6.webp";
import Project7 from "./assets/img/projects/p7.webp";
import Project8 from "./assets/img/projects/p8.webp";

// skills images
import SkillImg1 from "./assets/img/skills/html5.png";
import SkillImg2 from "./assets/img/skills/css3.png";
import SkillImg3 from "./assets/img/skills/js.png";
import SkillImg4 from "./assets/img/skills/reactjs.png";
import SkillImg5 from "./assets/img/skills/nextjs.png";
import SkillImg6 from "./assets/img/skills/nodejs.png";
import SkillImg7 from "./assets/img/skills/git.png";
import SkillImg8 from "./assets/img/skills/figma.png";

// testimonial images
import TestiImage1 from "./assets/img/testimonials/testimonial-1.webp";
import TestiImage2 from "./assets/img/testimonials/testimonial-2.webp";
import TestiImage3 from "./assets/img/testimonials/testimonial-3.jpg";
import TestiImage4 from "./assets/img/testimonials/testimonial-4.png";
import TestiImage5 from "./assets/img/testimonials/testimonial-5.png";
import TestiImage6 from "./assets/img/testimonials/testimonial-6.png";
import TestiImage7 from "./assets/img/testimonials/testimonial-7.png";

// navigation
export const navigation = [
  {
    name: "home",
    href: "home",
  },
  {
    name: "about",
    href: "about",
  },
  {
    name: "portfolio",
    href: "portfolio",
  },
  {
    name: "services",
    href: "services",
  },
  {
    name: "testimonials",
    href: "testimonials",
  },
  {
    name: "contact",
    href: "contact",
  },
];

// social
export const social = [
  {
    icon: <FiInstagram />,
    href: "https://www.instagram.com/euanmnrro/",
  },
  {
    icon: <FiGithub />,
    href: "https://github.com/emunro22",
  },
  {
    icon: <FiLinkedin />,
    href: "https://www.linkedin.com/in/euan-munro-ab5760215/",
  },
];

// companies
export const brands = [
  {
    img: FreelancerBrandIcon,
    href: "",
  },
  {
    img: UpworkBrandIcon,
    href: "",
  },
  {
    img: FiverBrandIcon,
    href: "",
  },
  {
    img: BehanceBrandIcon,
    href: "",
  },
  {
    img: DribbbleBrandIcon,
    href: "",
  },
];

// projects
export const projectsData = [
  {
    id: "1",
    image: Project1,
    name: "Holiday App UI",
    category: "UI/UX design",
  },
  {
    id: "2",
    image: Project2,
    name: "Cardano Gaming Website",
    category: "web development",
  },
  {
    id: "3",
    image: Project3,
    name: "Porsche 992 GT3 Design",
    category: "UI/UX design",
  },
  {
    id: "4",
    image: Project4,
    name: "Complex Sneakers Branding",
    category: "branding",
  },
  {
    id: "5",
    image: Project5,
    name: "Porsche Web Development",
    category: "web development",
  },
  {
    id: "6",
    image: Project6,
    name: "Web 3 Website",
    category: "web development",
  },
  {
    id: "7",
    image: Project7,
    name: "Watch Website",
    category: "web development",
  },
  {
    id: "8",
    image: Project8,
    name: "DXC Hub For Staff",
    category: "web development",
  },
];

// projects
export const projectsNav = [
  {
    name: "all",
  },
  {
    name: "UI/UX Design",
  },
  {
    name: "web development",
  },
  {
    name: "branding",
  },
];

// skill
export const skills = [
  {
    image: SkillImg1,
  },
  {
    image: SkillImg2,
  },
  {
    image: SkillImg3,
  },
  {
    image: SkillImg4,
  },
  {
    image: SkillImg5,
  },
  {
    image: SkillImg6,
  },
  {
    image: SkillImg7,
  },
  {
    image: SkillImg8,
  },
];

// services
export const services = [
  {
    icon: <FiLayout />,
    name: "Web Design",
    description:
      "I create visually stunning and user-friendly websites that leave a lasting impression. With a focus on modern design and cutting-edge technologies, I offer custom website design services that capture your brand's essence and effectively communicate your message.",
  },
  {
    icon: <FiSettings />,
    name: "Web Development",
    description:
      "I bring your designs to life with comprehensive web development services. From simple static websites to dynamic e-commerce platforms, I create visually appealing and highly functional websites that work seamlessly across devices and browsers.",
  },
  {
    icon: <FiPenTool />,
    name: "Branding",
    description:
      "I help businesses establish a distinctive and memorable identity through branding services. From logo design to brand guidelines, I create cohesive visual elements and messaging strategies that align with your brand's personality and goals.",
  },
  {
    icon: <FiTag />,
    name: "SEO",
    description:
      "I improve your website's visibility and organic rankings with effective SEO services. Through keyword research, on-page optimization, and technical enhancements, I help your website rank higher in search results and increase its online visibility.",
  },
];

// testimonials
export const testimonials = [
  {
    authorImg: TestiImage1,
    authorText:
      "Working with Euan was a game-changer for our business. They understood our vision and translated it into a stunning website that perfectly represents our brand. Their attention to detail, creativity, and ability to deliver results exceeded our expectations.",
    authorName: "Apex",
    authorPosition: "Founder, CGC",
    website: "https://cardanogamingcommunity.com/",
  },
  {
    authorImg: TestiImage2,
    authorText:
      "Euan is a true professional who goes above and beyond to ensure client satisfaction. Their expertise in web design and user experience helped us create a website that not only looks amazing but also drives conversions. We highly recommend Euan for anyone looking for top-notch web design services.",
    authorName: "Maek",
    authorPosition: "CEO, Phoenix Arena",
    website: "https://www.phoenixarena.io/",
  },
  {
    authorImg: TestiImage3,
    authorText:
      "We couldn't be happier with the website Euan designed for us. They listened to our requirements and provided valuable insights throughout the process. The result is a visually appealing and user-friendly website that has received numerous compliments from our customers.",
    authorName: "Deborah",
    authorPosition: "Site Lead, DXC Technology",
    website: "https://dxc.com/us/en/manutd",
  },
  {
    authorImg: TestiImage4,
    authorText:
      "Working with Euan on our website was an absolute pleasure. Their creativity, attention to detail, and technical expertise truly shone throughout the entire process. From the initial concept to the final execution, Euan demonstrated a deep understanding of our brand's identity and objectives.",
    authorName: "Shawaiz",
    authorPosition: "Co Owner, G Town Kettles",
    website: "https://gtownkettles.com",
  },
  {
    authorImg: TestiImage5,
    authorText:
      "Working with Euan has been a game-changer for my business. His professionalism, communication skills, and innovative problem-solving abilities have delivered outstanding results. Euan's dedication and expertise are unmatched, and I wholeheartedly recommend him as a trusted partner. Thank you, Euan, for your exceptional work!",
    authorName: "Malcolm",
    authorPosition: "Co Owner, MindsetToMoney",
    website: "https://mindsettomoneyclub.com/",
  },
  {
    authorImg: TestiImage6,
    authorText:
      "Euan's contribution has been invaluable. His professionalism, communication skills, and innovative problem-solving have driven our business forward. Highly recommend him for his exceptional service and the difference he's made!",
    authorName: "SRL Autos",
    authorPosition: "Owner, SRL Breakdown Recovery",
    website: "https://srlrecovery.com/",
  },
  {
    authorImg: TestiImage7,
    authorText:
      "Euan's impact has been transformative. His professionalism, clear communication, and innovative solutions have exceeded expectations. His commitment and expertise are unmatched. Highly recommend Euan for his remarkable contributions!",
    authorName: "Colin",
    authorPosition: "Owner, ClydeValleyGroup",
    website: "https://clydevalleygroup.com/",
  },
];

// contact
export const contact = [
  {
    icon: <FiMail />,
    title: "Have a question?",
    subtitle: "I am here to help you.",
    description: "Email Me @ euanmunroo@gmail.com",
  },
  {
    icon: <FiMapPin />,
    title: "Current Location",
    subtitle: "Scotland, United Kingdom ",
    description: "Serving Clients Worldwide",
  },
];
