import React from "react";

const Hero = () => {
  const scrollToContact = () => {
    document.getElementById("contact").scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <section
      id="home"
      className="lg:h-[85vh] flex items-center bg-primary lg:bg-cover lg:bg-center lg:bg-no-repeat py-32 lg:py-0 overflow-hidden"
    >
      <div className="container mx-auto h-full">
        <div className="flex items-center h-full pt-8">
          <div className="flex-1 flex flex-col items-center lg:items-start">
            <p className="text-lg text-accent mb-3 text-center lg:text-left">
              Hi, I'm Euan
            </p>
            <h1 className="text-4xl leading-44 md:text-5xl md:leading-tight lg:text-7xl lg:leading-10/12 font-bold md:tracking-[-2px] text-center lg:text-left">
              I Build &amp; Design <br />
              Web Interfaces while Studying at University ✔
            </h1>
            <p className="pt-4 pb-8 md:pt-6 md:pb-12 max-w-[480px] text-lg text-center lg:text-left">
              I'm a full-stack developer specialising in building (and
              occasionally designing) exceptional digital experiences.
              Currently, I'm focused on building responsive full-stack web
              applications.
            </p>
            <button
              className="btn btn-md bg-accent hover:bg-accent-hover md:btn-lg transition-all"
              onClick={scrollToContact}
            >
              Work with me
            </button>
            <div className="hidden lg:flex flex-1 justify-end items-end h-full"></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
