import React from "react";
import { social } from "../data";
import Logo from "../assets/img/logo.png";

const Footer = () => {
  const handleLogoClick = () => {
    // Logic to scroll to the home section or perform any desired action
    // For example, you can use the scrollTo() method to scroll to a specific section
    const homeSection = document.getElementById("home");
    if (homeSection) {
      homeSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <footer className="bg-tertiary py-12">
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row space-y-6 lg:space-y-0 items-center justify-between">
          <div className="flex space-x-6 items-center justify-center">
            {social.map((item, index) => {
              const { href, icon } = item;
              return (
                <a className="text-accent text-base" href={href} key={index}>
                  {icon}
                </a>
              );
            })}
          </div>

          <div>
            <img
              src={Logo}
              alt="Logo"
              style={{ width: "200px", height: "auto", cursor: "pointer" }}
              onClick={handleLogoClick}
            />
          </div>

          <p>&copy; 2023 Euan Munro. All Rights Reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
