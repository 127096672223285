import React, { useRef, useState } from "react";
import { contact } from "../data";
import emailjs from "emailjs-com";

const Contact = () => {
  const form = useRef();
  const [isMessageSent, setIsMessageSent] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_oc7dmau",
        "template_97nag5o",
        form.current,
        "U-ETnjHazXf65uTn6"
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log("Message Sent!");
          setIsMessageSent(true);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <section className="section bg-primary" id="contact">
      <div className="container mx-auto">
        <div className="flex flex-col items-center text-center">
          <h2 className="section-title before:content-contact relative before:absolute before:opacity-40 before:-top-7 before:-left-40 before:hidden before:lg:block">
            Contact Me
          </h2>
          <p className="subtitle">
            Whether you have a project in mind or simply want to discuss your
            ideas, I am here to listen and help bring your vision to life. With
            a user-friendly contact form and responsive communication, I ensure
            that reaching out is quick and effortless. I value open
            communication and prompt responses, so you can expect a timely and
            professional interaction. Let's collaborate and create something
            exceptional together.
          </p>
        </div>
      </div>

      <div className="container mx-auto flex flex-col lg:flex-row lg:gap-x-8">
        <div className="flex flex-1 flex-col items-start space-y-8 mb-12 lg:mb-0 lg:pt-2">
          {contact.map((item, index) => {
            const { icon, title, subtitle, description } = item;
            return (
              <div className="flex flex-col lg:flex-row gap-x-4" key={index}>
                <div className="text-accent rounded-sm w-14 h-14 flex items-start justify-center mt-2 mb-4 lg:mb-0 text-2xl">
                  {icon}
                </div>
                <div>
                  <h4 className="font-body text-xl mb-1">{title}</h4>
                  <p className="mb-1">{subtitle}</p>
                  <p className="text-accent font-normal">{description}</p>
                </div>
              </div>
            );
          })}
        </div>

        <form
          ref={form}
          className="space-y-8 w-full max-w-[780px]"
          onSubmit={sendEmail}
        >
          <input
            className="input"
            type="text"
            placeholder="Your Name?"
            name="user_name"
          />
          <input
            className="input"
            type="email"
            placeholder="Your Email"
            name="user_email"
          />
          <input
            className="input"
            type="text"
            placeholder="Subject"
            name="subject"
          />
          <textarea
            className="textarea"
            placeholder="Your Message"
            name="message"
          ></textarea>
          <button
            className="btn btn-lg bg-accent hover:bg-accent-hover"
            type="submit"
          >
            Send Message
          </button>
        </form>
      </div>

      {isMessageSent && (
        <div className="popup">
          <p>Message Sent Successfully!</p>
        </div>
      )}
    </section>
  );
};

export default Contact;
